<template>
<div>
    <div class="row form-group validated">
        <!-- first row -->
        <div class="col-xl-3 col-lg-4 col-md-6 col-sm-6">
            <span class="kt-font-danger">* </span>
            <label>Marking Period</label>
            <select
                v-model="schoolTermMarkingPeriodId"
                class="form-control"
                :disabled="deleted"
                :class="{'is-invalid': errors.markingPeriod}"
            >
                <option
                    v-for="option in markingPeriodSelect"
                    :key="option.value"
                    :value="option.value"
                >
                    {{ option.text }}
                </option>
            </select>
            <span
                v-if="errors.markingPeriod"
                class="form-text invalid-feedback"
            >
                {{ errors.markingPeriod }}
            </span>
        </div>
        <div class="col-xl-3 col-lg-4 col-md-6 col-sm-6">
            <label class="w-100">
                <span class="kt-font-danger">* </span>
                Grading Category
            </label>
            <select
                v-model="gradeTemplateCategoryId"
                :disabled="deleted"
                class="form-control"
                :class="{'is-invalid': errors.courseWorkCategory}"
            >
                <option
                    v-for="t in gradeTemplateCategories"
                    :key="`gradeTemplateCategories_${t.gradeTemplateCategoryId}`"
                    :value="t.gradeTemplateCategoryId"
                >
                    {{ t.categoryName }}
                </option>
            </select>
            <span
                v-if="errors.courseWorkCategory"
                class="form-text invalid-feedback"
            >
                {{ errors.courseWorkCategory }}
            </span>
        </div>

        <div class="col-xl-3 col-lg-4 col-md-6 col-sm-6">
            <!-- Publish Date -->
            <label>Publish Date</label>
            <b-input-group>
                <b-input-group-prepend>
                    <b-form-datepicker
                        v-model="publishDate"
                        :disabled="deleted"
                        button-only
                        autocomplete="off"
                        locale="en-US"
                    />
                </b-input-group-prepend>
                <b-form-input
                    v-model="publishDate"
                    :disabled="deleted"
                    type="date"
                    class="form-control kt-input"
                    :class="{'is-invalid': errors.publishDate}"
                    autocomplete="off"
                />
                <b-input-group-append>
                    <b-dropdown v-if="!deleted">
                        <b-dropdown-item-button
                            class="dropdown-item py-2 px-3"
                            @click.stop.prevent="publishDate = null"
                        >
                            Clear
                        </b-dropdown-item-button>
                        <b-dropdown-item-button
                            class="dropdown-item py-2 px-3"
                            @click.stop.prevent="publishDate = today"
                        >
                            Today
                        </b-dropdown-item-button>
                        <b-dropdown-item-button
                            class="dropdown-item py-2 px-3"
                            @click.stop.prevent="publishDate = tomorrow"
                        >
                            Tomorrow
                        </b-dropdown-item-button>
                        <b-dropdown-item-button
                            class="dropdown-item py-2 px-3"
                            @click.stop.prevent="publishDate = friday"
                        >
                            Friday
                        </b-dropdown-item-button>
                        <b-dropdown-item-button
                            class="dropdown-item py-2 px-3"
                            @click.stop.prevent="publishDate = monday"
                        >
                            Monday
                        </b-dropdown-item-button>
                    </b-dropdown>
                </b-input-group-append>
            </b-input-group>
        </div>
        <div class="col-xl-3 col-lg-4 col-md-6 col-sm-6">
            <!-- Publish Time -->
            <label>Publish Time</label>
            <b-input-group>
                <b-input-group-prepend>
                    <b-form-timepicker
                        v-model="publishTime"
                        :disabled="deleted"
                        :show-seconds="false"
                        hide-header
                        hour12
                        no-close-button
                        button-only
                        locale="en"
                    />
                </b-input-group-prepend>
                <b-form-input
                    v-model="publishTime"
                    :disabled="deleted"
                    type="time"
                    step="1"
                    :class="errors.publishDate ? 'is-invalid form-control kt-input' : 'form-control kt-input'"
                    autocomplete="off"
                />
                <b-input-group-append>
                    <b-dropdown v-if="!deleted">
                        <b-dropdown-item-button
                            class="dropdown-item py-2 px-3"
                            @click.stop.prevent="publishTime = null"
                        >
                            Clear
                        </b-dropdown-item-button>
                        <b-dropdown-item-button
                            class="dropdown-item py-2 px-3"
                            @click.stop.prevent="publishTime = '08:00:00'"
                        >
                            8:00 AM
                        </b-dropdown-item-button>
                        <b-dropdown-item-button
                            class="dropdown-item py-2 px-3"
                            @click.stop.prevent="publishTime = '15:00:00'"
                        >
                            3:00 PM
                        </b-dropdown-item-button>
                        <b-dropdown-item-button
                            class="dropdown-item py-2 px-3"
                            @click.stop.prevent="publishTime = '00:00:00'"
                        >
                            Midnight
                        </b-dropdown-item-button>
                    </b-dropdown>
                </b-input-group-append>
            </b-input-group>
        </div>

        <div
            v-if="errors.publishDate"
            class="col-12"
        >
            <span class="form-text invalid-feedback">
                {{ errors.publishDate }}
            </span>
        </div>

        <!-- second row -->

        <div class="col-xl-3 col-lg-4 col-md-6 col-sm-6">
            <span class="kt-font-danger">* </span>
            <label>{{ $t('pages.teacher.assignments.weight') }}</label>
            <input
                v-model="courseWorkWeight"
                :disabled="deleted"
                class="form-control"
                :class="{'is-invalid': errors.weight}"
                type="text"
            >
            <div
                v-if="errors.weight"
                class="form-text invalid-feedback"
            >
                {{ errors.weight }}
            </div>
        </div>
        <div class="col-xl-3 col-lg-4 col-md-6 col-sm-6">
            <span class="kt-font-danger">* </span>
            <label>{{ $t('pages.teacher.assignments.maxPoints') }}</label>
            <input
                v-model="maxPoints"
                :disabled="deleted"
                class="form-control"
                :class="{'is-invalid': errors.maxPoints}"
                type="text"
            >
            <div
                v-if="errors.maxPoints"
                class="form-text invalid-feedback"
            >
                {{ errors.maxPoints }}
            </div>
            <div class="kt-radio-inline mt-3 ml-2">
                <label class="kt-radio kt-radio--brand">
                    <input
                        v-model="allowExtraCredit"
                        type="checkbox"
                        value="Points"
                        :disabled="deleted"
                        name="averagingMethod"
                    >
                    <span />
                    Allow Extra Credit
                </label>
            </div>
        </div>
        <div class="col-xl-3 col-lg-4 col-md-6 col-sm-6">
            <label>Due Date</label>
            <b-input-group>
                <b-input-group-prepend>
                    <b-form-datepicker
                        v-model="dueDate"
                        :disabled="deleted"
                        button-only
                        autocomplete="off"
                        locale="en-US"
                    />
                </b-input-group-prepend>
                <b-form-input
                    v-model="dueDate"
                    type="date"
                    :disabled="deleted"
                    class="form-control kt-input"
                    :class="{'is-invalid': errors.dueDate}"
                    autocomplete="off"
                />
                <b-input-group-append>
                    <b-dropdown v-if="!deleted">
                        <b-dropdown-item-button
                            class="dropdown-item py-2 px-3"
                            @click.stop.prevent="dueDate = null"
                        >
                            Clear
                        </b-dropdown-item-button>
                        <b-dropdown-item-button
                            class="dropdown-item py-2 px-3"
                            @click.stop.prevent="dueDate = today"
                        >
                            Today
                        </b-dropdown-item-button>
                        <b-dropdown-item-button
                            class="dropdown-item py-2 px-3"
                            @click.stop.prevent="dueDate = tomorrow"
                        >
                            Tomorrow
                        </b-dropdown-item-button>
                        <b-dropdown-item-button
                            class="dropdown-item py-2 px-3"
                            @click.stop.prevent="dueDate = friday"
                        >
                            Friday
                        </b-dropdown-item-button>
                        <b-dropdown-item-button
                            class="dropdown-item py-2 px-3"
                            @click.stop.prevent="dueDate = monday"
                        >
                            Monday
                        </b-dropdown-item-button>
                    </b-dropdown>
                </b-input-group-append>
            </b-input-group>
        </div>
        <div class="col-xl-3 col-lg-4 col-md-6 col-sm-6">
            <!-- Due Time -->
            <label>Due Time</label>
            <b-input-group>
                <b-input-group-prepend>
                    <b-form-timepicker
                        v-model="dueTime"
                        :show-seconds="false"
                        :disabled="deleted"
                        hour12
                        hide-header
                        no-close-button
                        button-only
                        locale="en"
                    />
                </b-input-group-prepend>
                <b-form-input
                    v-model="dueTime"
                    type="time"
                    step="1"
                    :disabled="deleted"
                    :class="errors.dueDate ? 'is-invalid form-control kt-input' : 'form-control kt-input'"
                    autocomplete="off"
                />
                <b-input-group-append>
                    <b-dropdown v-if="!deleted">
                        <b-dropdown-item-button
                            class="dropdown-item py-2 px-3"
                            @click.stop.prevent="dueTime = null"
                        >
                            Clear
                        </b-dropdown-item-button>
                        <b-dropdown-item-button
                            class="dropdown-item py-2 px-3"
                            @click.stop.prevent="dueTime = '08:00:00'"
                        >
                            8:00 AM
                        </b-dropdown-item-button>
                        <b-dropdown-item-button
                            class="dropdown-item py-2 px-3"
                            @click.stop.prevent="dueTime = '15:00:00'"
                        >
                            3:00 PM
                        </b-dropdown-item-button>
                        <b-dropdown-item-button
                            class="dropdown-item py-2 px-3"
                            @click.stop.prevent="dueTime = '00:00:00'"
                        >
                            Midnight
                        </b-dropdown-item-button>
                    </b-dropdown>
                </b-input-group-append>
            </b-input-group>
        </div>

        <div
            v-if="errors.dueDate"
            class="col-12"
        >
            <span class="form-text invalid-feedback">
                {{ errors.dueDate }}
            </span>
        </div>
        <div class="col-12" />
        <!-- third row -->
        <div class="col-xl-1 col-lg-2 col-sm-4">
            <label>
                Id
            </label>

            <input
                v-model="teacherExtCourseWorkId"
                :disabled="deleted"
                class="form-control"
                maxlength="4"
                type="text"
            >
        </div>
        <div class="col-xl-11 col-lg-10 col-sm-8">
            <label>
                <span class="kt-font-danger">* </span>
                {{ $t('pages.teacher.assignments.title') }}
            </label>

            <input
                v-model="courseWorkTitle"
                class="form-control"
                :disabled="deleted"
                :class="{'is-invalid': errors.courseWorkTitle}"
                type="text"
            >
            <div
                v-if="errors.courseWorkTitle"
                class="form-text invalid-feedback"
            >
                {{ errors.courseWorkTitle }}
            </div>
        </div>

        <!-- fourth row -->
        <CourseWorkLinker v-if="isNew">
            <template #header>
                <div class="row">
                    <div class="col-12">
                        <div class="form-group mb-3">
                            <label class="kt-font-md w-100">
                                Assigned Courses
                            </label>
                        </div>
                    </div>
                </div>
            </template>
            <template #error>
                <span
                    v-if="errors.assignedCourses"
                    class="kt-font-danger"
                >
                    {{ errors.assignedCourses }}
                </span>
            </template>
        </CourseWorkLinker>

        <!-- Fifth Row -->
        <div class="col-12">
            <!-- Description -->
            <label>Assignment Description</label>

            <v-md-editor
                v-if="!deleted"
                v-model="courseWorkDescription"
                height="400px"
                :right-toolbar="rightToolbar"
                :left-toolbar="leftToolbar"
                mode="edit"
                class="v-md-editor"
            />
            <textarea
                v-else
                v-model="courseWorkDescription"
                rows="10"
                class="form-control w-100"
                :disabled="'disabled'"
            />
            <span
                class="form-text text-muted"
                style="font-size: 0.8rem"
            >
                <i class="fab fa-markdown" />
                You can use
                <a
                    target="_blank"
                    href="https://commonmark.org/help/"
                >
                    Markdown
                </a>
                to format your description.
            </span>
        </div>

        <div
            v-if="errors.publishDate"
            class="col-12"
        >
            <span class="form-text invalid-feedback">
                {{ errors.publishDate }}
            </span>
        </div>
    </div>
</div>
</template>

<script lang="ts">
import Vue from 'vue';
import moment from 'moment';
import _ from 'lodash';
import Types from '../store/Types';
import CourseWorkLinker from './CourseWorkLinker.vue';
import teacherMixins from '../store/mixins/teacherMixins';
import courseMixins, { getCourses, CourseFilter } from '../store/mixins/courseMixins';
import { courseWorkMixins } from '../store/mixins/courseWorkMixins';
import blankAssignment from '../store/state/assignment';

export default Vue.extend({
    name: 'LocalCourseWork',
    components: {
        CourseWorkLinker,
    },
    mixins: [
        teacherMixins,
        courseMixins,
        courseWorkMixins,
    ],
    data() {
        return {
            leftToolbar: 'h bold italic strikethrough quote | ul ol table hr | link',
            rightToolbar: 'fullscreen | preview',
            errors: {
                courseWorkTitle: null,
                courseWorkCategory: null,
                courses: null,
                weight: null,
                maxPoints: null,
                publishDate: null,
                dueDate: null,
                markingPeriod: null,
                assignedCourses: null,
            },
        };
    },
    computed: {
        today() {
            return moment().format('YYYY-MM-DD');
        },
        tomorrow() {
            return moment().add(1, 'day').format('YYYY-MM-DD');
        },
        friday() {
            return moment().startOf('week').day(5).format('YYYY-MM-DD');
        },
        monday() {
            return moment().startOf('week')
                .add(1, 'week').day(1)
                .format('YYYY-MM-DD');
        },
        user() {
            return this.$store.state.user;
        },
        isNew() {
            return this.$route.name == 'TeacherCourseAssignmentCreate';
        },
        isEdit() {
            return this.$route.name == 'TeacherLocalCourseAssignmentEdit';
        },
        dirty() {
            return this.$store.state.database.assignmentIsDirty;
        },
        routeCourseSectionId() {
            return this.$route.query.courseSectionId;
        },
        assignmentCourseSections() {
            return this.$store.state.database.assignmentCourseSections;
        },
        primaryCourse() {
            return this.$_courseMixins_getCourseFromRoute();
        },
        primaryTeacher() {
            return this.$_teacherMixins_getTeacherFromRoute();
        },
        selectedCourse() {
            const { teacherCourses, isNew } = this;
            if (isNew) {
                const { primaryCourse } = this;
                return teacherCourses.find((cw) => cw.courseSectionId == primaryCourse.courseSectionId) || null;
            }
            if (this.$route.query.courseSectionId) {
                const { courseSectionId } = this.$route.query;
                return teacherCourses.find((cw) => cw.courseSectionId == courseSectionId) || null;
            }
            return null;
        },
        primaryCourseWork() {
            return this.$_courseWorkMixins_getCourseWorkFromRoute();
        },
        teacherCourses() {
            if (!this.primaryTeacher) return [];
            const { database } = this.$store.state;
            const { schoolStaffId } = this.primaryTeacher;
            const courses = getCourses(database, new CourseFilter({ schoolStaffId }));
            return courses;
        },
        linkedAssignments() {
            if (!this.isEdit) return [];
            if (!this.primaryCourseWork) return [];
            const { $store, teacherCourses } = this;
            const { linkingGuid } = this.primaryCourseWork;
            const output = $store.state.database.courseWork
                .filter((a) => a.linkingGuid == linkingGuid && a.deleted == false)
                .map((cw) => {
                    const courseWork = { ...cw };
                    const { courseSectionId } = cw;
                    courseWork.course = teacherCourses.find((c) => c.courseSectionId == courseSectionId) || null;
                    return courseWork;
                });
            return output;
        },
        markingPeriods() {
            return this.$store.state.database.markingPeriods
                .filter((mp) => !mp.deleted)
                .sort((a, b) => ((a.markingPeriod > b.markingPeriod) ? 1 : ((b.markingPeriod > a.markingPeriod) ? -1 : 0)));
        },
        gradeTemplateCategories() {
            const { gradeTemplate } = this;
            if (!gradeTemplate) return [];

            const categories = gradeTemplate.categories.slice();
            categories.unshift({
                gradeTemplateCategoryId: 0,
                categoryName: 'No Category',
            });

            return categories;
        },
        gradeTemplate() {
            const { selectedCourse } = this;
            if (!selectedCourse) return null;
            return selectedCourse.gradeTemplate || null;
        },
        markingPeriodSelect() {
            const { markingPeriods } = this;
            const mps = markingPeriods.filter((mp) => !mp.deleted).map((mp) => ({
                value: mp.schoolTermMarkingPeriodId,
                text: mp.markingPeriod,
            }));
            mps.unshift({
                value: null,
                text: 'No Marking Period',
            });
            return mps;
        },
        courseWorks() {
            return this.$store.state.database.courseWorks;
        },
        courseWork() {
            return this.$store.state.database.assignment;
        },
        courseWorkTitle: {
            set: _.debounce(function (newValue) {
                this.$store.commit(Types.mutations.SET_COURSE_WORK, { courseWorkTitle: newValue });
            }, 500),
            get() {
                return this.$store.state.database.assignment.courseWorkTitle;
            },
        },
        allowExtraCredit: {
            set(newValue) {
                this.$store.commit(Types.mutations.SET_COURSE_WORK, { allowExtraCredit: newValue });
            },
            get() {
                return this.$store.state.database.assignment.allowExtraCredit;
            },
        },
        teacherExtCourseWorkId: {
            set: _.debounce(function (newValue) {
                this.$store.commit(Types.mutations.SET_COURSE_WORK, { teacherExtCourseWorkId: newValue });
            }, 500),
            get() {
                return this.$store.state.database.assignment.teacherExtCourseWorkId;
            },
        },
        courseWorkDescription: {
            set: _.debounce(function (newValue) {
                this.$store.commit(Types.mutations.SET_COURSE_WORK, { courseWorkDescription: newValue });
            }, 750),
            get() {
                return this.$store.state.database.assignment.courseWorkDescription;
            },
        },
        // assigneeMode: {},
        courseWorkWeight: {
            set: _.debounce(function (newValue) {
                this.$store.commit(Types.mutations.SET_COURSE_WORK, { courseWorkWeight: newValue });
            }, 750),
            get() {
                return this.$store.state.database.assignment.courseWorkWeight;
            },
        },
        gradeTemplateCategoryId: {
            set(newValue) {
                this.$store.commit(Types.mutations.SET_COURSE_WORK, { gradeTemplateCategoryId: newValue });
            },
            get() {
                return this.$store.state.database.assignment.gradeTemplateCategoryId;
            },
        },
        schoolTermMarkingPeriodId: {
            set(newValue) {
                this.$store.commit(Types.mutations.SET_COURSE_WORK, { schoolTermMarkingPeriodId: newValue });
            },
            get() {
                return this.$store.state.database.assignment.schoolTermMarkingPeriodId;
            },
        },
        maxPoints: {
            set: _.debounce(function (newValue) {
                this.$store.commit(Types.mutations.SET_COURSE_WORK, { maxPoints: newValue });
            }, 750),
            get() {
                return this.$store.state.database.assignment.maxPoints;
            },
        },
        dueDate: {
            set(newValue) {
                this.$store.commit(Types.mutations.SET_COURSE_WORK, { dueDate: newValue });
            },
            get() {
                return this.$store.state.database.assignment.dueDate;
            },
        },
        dueTime: {
            set(newValue) {
                this.$store.commit(Types.mutations.SET_COURSE_WORK, { dueTime: newValue });
            },
            get() {
                return this.$store.state.database.assignment.dueTime;
            },
        },
        publishDate: {
            set(newValue) {
                this.$store.commit(Types.mutations.SET_COURSE_WORK, { publishDate: newValue });
            },
            get() {
                return this.$store.state.database.assignment.publishDate;
            },
        },
        publishTime: {
            set(newValue) {
                this.$store.commit(Types.mutations.SET_COURSE_WORK, { publishTime: newValue });
            },
            get() {
                return this.$store.state.database.assignment.publishTime;
            },
        },
        courseSectionId: {
            set(newValue) {
                this.$store.commit(Types.mutations.SET_COURSE_WORK, { courseSectionId: newValue });
            },
            get() {
                return this.$store.state.database.assignment.courseSectionId;
            },
        },
        excluded: {
            set(newValue) {
                this.$store.commit(Types.mutations.SET_COURSE_WORK, { excluded: newValue });
            },
            get() {
                return this.$store.state.database.assignment.excluded;
            },
        },
        hideMarks: {
            set(newValue) {
                this.$store.commit(Types.mutations.SET_COURSE_WORK, { hideMarks: newValue });
            },
            get() {
                return this.$store.state.database.assignment.hideMarks;
            },
        },
        deleted() {
            return this.$store.state.database.assignment.deleted;
        },
    },
    watch: {
        routeCourseSectionId() {
            this.populate();
        },
        courseWorks: {
            handler() {
                this.populate();
            },
            deep: true,
        },
        assignmentCourseSections: {
            handler() {
                this.populate();
            },
            deep: true,
        },
    },
    mounted() {
        this.populate();
    },
    methods: {
        populate() {
            // populate the mutable courseWork
            // object with the primary courseWork
            if (this.isEdit) {
                this.populateCourseWork();
            } else {
                this.courseWork.schoolTermMarkingPeriodId = this.$store.state.settings.schoolTermMarkingPeriodId;
            }
            const { commit } = this.$store;
            this.$nextTick(() => {
                commit(Types.mutations.SET_ASSIGNMENT_IS_DIRTY, false);
            });
        },
        populateCourseWork() {
            const { isNew, isEdit, gradeTemplateCategories } = this;
            const { primaryCourseWork, gradeTemplate } = this;

            if (isEdit) {
                if (!primaryCourseWork) return console.error('No primary course work found');
                const { courseSectionId } = this.$route.query;
                if (!courseSectionId) return null;
                const { linkingGuid } = primaryCourseWork;

                const exists = this.$store.state.database.courseWork.find((c) => c.courseSectionId == courseSectionId && c.linkingGuid == linkingGuid);
                const courseWork = { ...(exists || primaryCourseWork) };

                courseWork.courseSectionId = courseSectionId;
                if (!exists) {
                    courseWork.deleted = true;
                    delete courseWork.courseWorkId;
                }

                const { gradeTemplateCategoryId } = courseWork;
                const category = gradeTemplateCategories.find((c) => gradeTemplateCategoryId && c.gradeTemplateCategoryId == gradeTemplateCategoryId) || null;
                if (category) {
                    courseWork.gradeTemplateId = gradeTemplate.gradeTemplateId;
                    courseWork.gradeCategoryId = category.gradeCategoryId;
                    courseWork.categoryName = category.categoryName;
                    courseWork.color = category.categoryColor;
                }
                if (exists) {
                    courseWork.courseWorkTitle = exists.courseWorkTitle;
                    courseWork.allowExtraCredit = exists.allowExtraCredit;
                    courseWork.teacherExtCourseWorkId = exists.teacherExtCourseWorkId;
                    courseWork.courseWorkDescription = exists.courseWorkDescription;
                }

                this.$store.commit(Types.mutations.SET_COURSE_WORK, courseWork);
                this.$store.commit(Types.mutations.SET_ASSIGNMENT_IS_DIRTY, false);
            }

            if (isNew) {
                const courseWork = { ...blankAssignment };
                courseWork.courseSectionId = this.selectedCourse.courseSectionId;
                this.$store.commit(Types.mutations.SET_COURSE_WORK, courseWork);
                this.$store.commit(Types.mutations.SET_ASSIGNMENT_IS_DIRTY, false);
            }
        },
        isValid() {
            const { courseWork, errors } = this;
            const { gradeTemplate, isNew } = this;

            errors.courseWorkTitle = null;
            errors.courseWorkCategory = null;
            errors.courses = null;
            errors.weight = null;
            errors.maxPoints = null;
            errors.publishDate = null;
            errors.dueDate = null;
            errors.markingPeriod = null;
            errors.assignedCourses = null;

            let isValid = true;
            if (!this.courseWorkTitle || this.courseWorkTitle.length == 0) {
                errors.courseWorkTitle = 'Title is required';
                isValid = false;
            }
            if (!courseWork.gradeTemplateCategoryId) {
                errors.courseWorkCategory = 'Category selection is required';
                isValid = false;
            }

            if (!courseWork.courseWorkWeight) {
                errors.weight = 'Weight is required';
                isValid = false;
            }

            if (!courseWork.schoolTermMarkingPeriodId) {
                errors.markingPeriod = 'Marking Period is required';
                isValid = false;
            }

            if (!/^[0-9]\d*(\.\d+)?$/.test(courseWork.courseWorkWeight)) {
                if (parseFloat(courseWork.courseWorkWeight) < 0 && !errors.weight) {
                    errors.weight = 'Weight must be a positive number';
                    isValid = false;
                }
                if (!errors.weight) {
                    errors.weight = 'Weight must be a positive number';
                    isValid = false;
                }
            }

            if (gradeTemplate.maxWeight && courseWork.courseWorkWeight > gradeTemplate.maxWeight) {
                if (!errors.weight) {
                    errors.weight = `Weight cant be more than ${gradeTemplate.maxWeight}`;
                    isValid = false;
                }
            }

            if (!courseWork.maxPoints) {
                errors.maxPoints = 'Max Points is required';
                isValid = false;
            }

            if (!/^\d+$/.test(courseWork.maxPoints)) {
                if (!errors.maxPoints) {
                    errors.maxPoints = 'Max Points must be a positive whole number';
                    isValid = false;
                }
            }

            if (gradeTemplate.maxPoints && courseWork.maxPoints > gradeTemplate.maxPoints) {
                if (!errors.maxPoints) {
                    errors.maxPoints = `Max Points cant be greater than ${gradeTemplate.maxPoints}`;
                    isValid = false;
                }
            }

            if (courseWork.dueTime && !courseWork.dueDate) {
                errors.dueDate = 'If submitting a dueDate, you must also submit a date';
                isValid = false;
            } else if (courseWork.dueDate) {
                const format = 'YYYY-MM-DD HH:mm:ss';
                const dateTime = moment(`${courseWork.dueDate} ${courseWork.dueTime || '00:00:00'}`, format);
                if (dateTime.format(format) !== `${courseWork.dueDate} ${courseWork.dueTime || '00:00:00'}`) {
                    errors.dueDate = 'Please pick a valid due date and time';
                    isValid = false;
                }
            }

            if (courseWork.publishTime && !courseWork.publishDate) {
                errors.publishDate = 'If submitting a publish time, you must also submit a date';
                isValid = false;
            } else if (courseWork.publishDate) {
                const format = 'YYYY-MM-DD HH:mm:ss';
                const dateTime = moment(`${courseWork.publishDate} ${courseWork.publishTime || '00:00:00'}`, format);
                if (dateTime.format(format) !== `${courseWork.publishDate} ${courseWork.publishTime || '00:00:00'}`) {
                    errors.publishDate = 'Please pick a valid publish date and time';
                    isValid = false;
                }
            }
            if (isNew) {
                const hasAssigned = this.assignmentCourseSections.some((c) => c.assigned);
                if (!hasAssigned) {
                    errors.assignedCourses = 'You must assign this assignment to at least one course';
                    isValid = false;
                }
            }

            return isValid;
        },
    },
});

</script>

<style scoped>

tbody label.kt-checkbox {
    width: 100%;
}
tbody label.kt-checkbox span {
    margin-top: 10px;
}
tbody .kt-checkbox.kt-checkbox--single {
    height: auto;
    margin-bottom: 0;
}
.sg-info-container {
    display: flex;
    flex-direction: row;
    padding-top: 5px;
    padding-bottom: 20px;
    margin-bottom: 10px;
    border-bottom: 1px #f0f0f0 solid;
}
.sg-info-item {
    display: flex;
    flex-direction: row;
    padding-left: 10px;
    padding-right: 10px;
    width: 100%
}
.sg-info-item.first {
    padding: 0px 10px 0px 0px;
    border-right: 1px #e2e5ec solid;
}
.sg-info-item.last {
    padding: 0px 0px 0px 10px;
    border-left: 1px #e2e5ec solid;
}
span.sg-info-title {
    font-weight: 500;
    font-size: 1.1rem;
    margin-right: 20px;
}
span.sg-info-detail {
    font-weight: 300;
    font-size: 1.1rem;
}
.kt-widget28__tab-item {
    padding-bottom: 20px;
}
span.sg-switch {
    display: flex;
    width: 100%;
    padding: 0.65rem 0rem 0rem 0rem;
    flex-direction: row-reverse;
}
td.delta-warning {
    vertical-align: middle;
    padding-right: 1em;
    font-size: 20px;
}
.error {
    border: 1px red solid;
}
.form-group.validated > div {
    padding-top: 1.2rem;
}
input[type="time"]::-webkit-calendar-picker-indicator {
    background: none;
    display:none;
}

input[type="date"]::-webkit-calendar-picker-indicator {
    background: none;
    display:none;
}

.no-wrap-line {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 1.6rem;
}
</style>
