<template>
<div class="w-100 px-3 pt-3 mt-4">
    <slot name="header" />
    <div class="row">
        <div
            v-for="course in courses"
            :key="`ass-course-${course.courseSectionId}`"
            class="col-xl-3 col-lg-4 col-md-6 col-sm-12"
        >
            <div
                class="form-group"
                @click.stop.prevent="selectCourse(course.courseSectionId)"
            >
                <label class="w-100 pb-1 mb-2">
                    <a
                        href="#"
                        class="ml-1"
                        :class="course.assigned ? 'text-primary' : 'text-muted'"
                        @click.stop.prevent="selectCourse(course.courseSectionId)"
                    >
                        <CourseIcon
                            v-if="course.assigned"
                            role="button"
                            :course="course"
                            size="xs"
                            class="mt-0 mr-1"
                        />
                        <span
                            v-else
                            class="btn btn-icon mt-0 mr-1 Grey btn-xs"
                            role="button"
                        >
                            <span class="course-icon">x</span>
                        </span>
                        {{ course.courseSectionTitle || course.name }}
                    </a>
                </label>
                <div class="input-group">
                    <div class="input-group-prepend">
                        <span class="input-group-text">
                            <label
                                class="kt-checkbox kt-checkbox--single"
                                :class="course.assigned ? 'kt-checkbox--success' : ''"
                            >
                                <input
                                    v-model="course.assigned"
                                    type="checkbox"
                                >
                                <span />
                            </label>
                        </span>
                    </div>
                    <input
                        readonly="readonly"
                        onclick="this.blur();"
                        role="button"
                        :value="course.assigned ? 'Assigned' : ''"
                        class="form-control"
                        :placeholder="!course.assigned ? 'Not Assigned':''"
                    >
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <div class="form-group mb-0">
                <label class="kt-font-md w-100">
                    <slot name="error" />
                    <span class="form-text text-muted pull-right">
                        <a
                            href="#"
                            @click.stop.prevent="selectAll"
                        >All</a> |
                        <a
                            href="#"
                            @click.stop.prevent="selectNone"
                        >None</a> |
                        <a
                            href="#"
                            @click.stop.prevent="selectInvert"
                        >Invert</a>
                    </span>
                </label>
            </div>
        </div>
    </div>
</div>
</template>
<script lang="ts">

import Vue from 'vue';
import CourseIcon from './CourseIcon.vue';
import { getCourses, CourseFilter } from '../store/mixins/courseMixins';
import { getSessionUser } from '../store/mixins/userMixins';
import teacherMixins from '../store/mixins/teacherMixins';
import Types from '../store/Types';

export default Vue.extend({
    name: 'CourseWorkLinker',
    components: {
        CourseIcon,
    },
    mixins: [teacherMixins],
    props: {

    },
    computed: {
        courses: {
            get() {
                return this.$store.state.database.assignmentCourseSections;
            },
            set(assignmentCourseSections) {
                this.$store.commit(Types.mutations.SET_ASSIGNMENT_COURSE_SECTIONS, { assignmentCourseSections });
            },
        },
        teacher() {
            return this.$_teacherMixins_getTeacherFromRoute();
        },
    },
    mounted() {
        this.populate();
    },
    methods: {
        isNew() {
            return this.$route.name == 'LocalCourseAssignmentCreate';
        },
        isEdit() {
            return this.$route.name == 'TeacherLocalCourseAssignmentEdit';
        },
        populate() {
            if (!this.teacher) return;
            const { schoolStaffId } = this.teacher;
            const session = getSessionUser(this.$store.state);
            const courses = getCourses(this.$store.state.database, new CourseFilter({ schoolStaffId, session }));
            courses
                // .filter((x) => !x.hideFromNav)
                .map((c) => {
                    const course = { ...c };
                    course.assigned = true;
                    return course;
                })
                .sort((a, b) => (`${a.periodSort}` > `${b.periodSort}` ? 1 : -1));

            this.courses = courses;
        },
        selectCourse(courseSectionId) {
            const courses = this.courses.map((c) => {
                if (c.courseSectionId == courseSectionId) {
                    const course = { ...c };
                    course.assigned = !course.assigned;
                    return course;
                }
                return c;
            });
            this.courses = [...courses];
        },
        selectNone() {
            const courses = this.courses.map((c) => {
                const course = c;
                if (course.disabled) return course;
                course.assigned = false;
                return course;
            });
            this.courses = [...courses];
        },
        selectAll() {
            const courses = this.courses.map((c) => {
                const course = c;
                if (course.disabled) return course;
                course.assigned = true;
                return course;
            });
            this.courses = [...courses];
        },
        selectInvert() {
            const courses = this.courses.map((c) => {
                const course = c;
                if (course.disabled) return course;
                course.assigned = !course.assigned;
                return course;
            });
            this.courses = [...courses];
        },
    },
});
</script>
