var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "w-100 px-3 pt-3 mt-4" },
    [
      _vm._t("header"),
      _c(
        "div",
        { staticClass: "row" },
        _vm._l(_vm.courses, function (course) {
          return _c(
            "div",
            {
              key: `ass-course-${course.courseSectionId}`,
              staticClass: "col-xl-3 col-lg-4 col-md-6 col-sm-12",
            },
            [
              _c(
                "div",
                {
                  staticClass: "form-group",
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      $event.preventDefault()
                      return _vm.selectCourse(course.courseSectionId)
                    },
                  },
                },
                [
                  _c("label", { staticClass: "w-100 pb-1 mb-2" }, [
                    _c(
                      "a",
                      {
                        staticClass: "ml-1",
                        class: course.assigned ? "text-primary" : "text-muted",
                        attrs: { href: "#" },
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            $event.preventDefault()
                            return _vm.selectCourse(course.courseSectionId)
                          },
                        },
                      },
                      [
                        course.assigned
                          ? _c("CourseIcon", {
                              staticClass: "mt-0 mr-1",
                              attrs: {
                                role: "button",
                                course: course,
                                size: "xs",
                              },
                            })
                          : _c(
                              "span",
                              {
                                staticClass:
                                  "btn btn-icon mt-0 mr-1 Grey btn-xs",
                                attrs: { role: "button" },
                              },
                              [
                                _c("span", { staticClass: "course-icon" }, [
                                  _vm._v("x"),
                                ]),
                              ]
                            ),
                        _vm._v(
                          " " +
                            _vm._s(course.courseSectionTitle || course.name) +
                            " "
                        ),
                      ],
                      1
                    ),
                  ]),
                  _c("div", { staticClass: "input-group" }, [
                    _c("div", { staticClass: "input-group-prepend" }, [
                      _c("span", { staticClass: "input-group-text" }, [
                        _c(
                          "label",
                          {
                            staticClass: "kt-checkbox kt-checkbox--single",
                            class: course.assigned
                              ? "kt-checkbox--success"
                              : "",
                          },
                          [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: course.assigned,
                                  expression: "course.assigned",
                                },
                              ],
                              attrs: { type: "checkbox" },
                              domProps: {
                                checked: Array.isArray(course.assigned)
                                  ? _vm._i(course.assigned, null) > -1
                                  : course.assigned,
                              },
                              on: {
                                change: function ($event) {
                                  var $$a = course.assigned,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? true : false
                                  if (Array.isArray($$a)) {
                                    var $$v = null,
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        _vm.$set(
                                          course,
                                          "assigned",
                                          $$a.concat([$$v])
                                        )
                                    } else {
                                      $$i > -1 &&
                                        _vm.$set(
                                          course,
                                          "assigned",
                                          $$a
                                            .slice(0, $$i)
                                            .concat($$a.slice($$i + 1))
                                        )
                                    }
                                  } else {
                                    _vm.$set(course, "assigned", $$c)
                                  }
                                },
                              },
                            }),
                            _c("span"),
                          ]
                        ),
                      ]),
                    ]),
                    _c("input", {
                      staticClass: "form-control",
                      attrs: {
                        readonly: "readonly",
                        onclick: "this.blur();",
                        role: "button",
                        placeholder: !course.assigned ? "Not Assigned" : "",
                      },
                      domProps: { value: course.assigned ? "Assigned" : "" },
                    }),
                  ]),
                ]
              ),
            ]
          )
        }),
        0
      ),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-12" }, [
          _c("div", { staticClass: "form-group mb-0" }, [
            _c(
              "label",
              { staticClass: "kt-font-md w-100" },
              [
                _vm._t("error"),
                _c("span", { staticClass: "form-text text-muted pull-right" }, [
                  _c(
                    "a",
                    {
                      attrs: { href: "#" },
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          $event.preventDefault()
                          return _vm.selectAll.apply(null, arguments)
                        },
                      },
                    },
                    [_vm._v("All")]
                  ),
                  _vm._v(" | "),
                  _c(
                    "a",
                    {
                      attrs: { href: "#" },
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          $event.preventDefault()
                          return _vm.selectNone.apply(null, arguments)
                        },
                      },
                    },
                    [_vm._v("None")]
                  ),
                  _vm._v(" | "),
                  _c(
                    "a",
                    {
                      attrs: { href: "#" },
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          $event.preventDefault()
                          return _vm.selectInvert.apply(null, arguments)
                        },
                      },
                    },
                    [_vm._v("Invert")]
                  ),
                ]),
              ],
              2
            ),
          ]),
        ]),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }