var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", [
    _c(
      "div",
      { staticClass: "row form-group validated" },
      [
        _c("div", { staticClass: "col-xl-3 col-lg-4 col-md-6 col-sm-6" }, [
          _c("span", { staticClass: "kt-font-danger" }, [_vm._v("* ")]),
          _c("label", [_vm._v("Marking Period")]),
          _c(
            "select",
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.schoolTermMarkingPeriodId,
                  expression: "schoolTermMarkingPeriodId",
                },
              ],
              staticClass: "form-control",
              class: { "is-invalid": _vm.errors.markingPeriod },
              attrs: { disabled: _vm.deleted },
              on: {
                change: function ($event) {
                  var $$selectedVal = Array.prototype.filter
                    .call($event.target.options, function (o) {
                      return o.selected
                    })
                    .map(function (o) {
                      var val = "_value" in o ? o._value : o.value
                      return val
                    })
                  _vm.schoolTermMarkingPeriodId = $event.target.multiple
                    ? $$selectedVal
                    : $$selectedVal[0]
                },
              },
            },
            _vm._l(_vm.markingPeriodSelect, function (option) {
              return _c(
                "option",
                { key: option.value, domProps: { value: option.value } },
                [_vm._v(" " + _vm._s(option.text) + " ")]
              )
            }),
            0
          ),
          _vm.errors.markingPeriod
            ? _c("span", { staticClass: "form-text invalid-feedback" }, [
                _vm._v(" " + _vm._s(_vm.errors.markingPeriod) + " "),
              ])
            : _vm._e(),
        ]),
        _c("div", { staticClass: "col-xl-3 col-lg-4 col-md-6 col-sm-6" }, [
          _vm._m(0),
          _c(
            "select",
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.gradeTemplateCategoryId,
                  expression: "gradeTemplateCategoryId",
                },
              ],
              staticClass: "form-control",
              class: { "is-invalid": _vm.errors.courseWorkCategory },
              attrs: { disabled: _vm.deleted },
              on: {
                change: function ($event) {
                  var $$selectedVal = Array.prototype.filter
                    .call($event.target.options, function (o) {
                      return o.selected
                    })
                    .map(function (o) {
                      var val = "_value" in o ? o._value : o.value
                      return val
                    })
                  _vm.gradeTemplateCategoryId = $event.target.multiple
                    ? $$selectedVal
                    : $$selectedVal[0]
                },
              },
            },
            _vm._l(_vm.gradeTemplateCategories, function (t) {
              return _c(
                "option",
                {
                  key: `gradeTemplateCategories_${t.gradeTemplateCategoryId}`,
                  domProps: { value: t.gradeTemplateCategoryId },
                },
                [_vm._v(" " + _vm._s(t.categoryName) + " ")]
              )
            }),
            0
          ),
          _vm.errors.courseWorkCategory
            ? _c("span", { staticClass: "form-text invalid-feedback" }, [
                _vm._v(" " + _vm._s(_vm.errors.courseWorkCategory) + " "),
              ])
            : _vm._e(),
        ]),
        _c(
          "div",
          { staticClass: "col-xl-3 col-lg-4 col-md-6 col-sm-6" },
          [
            _c("label", [_vm._v("Publish Date")]),
            _c(
              "b-input-group",
              [
                _c(
                  "b-input-group-prepend",
                  [
                    _c("b-form-datepicker", {
                      attrs: {
                        disabled: _vm.deleted,
                        "button-only": "",
                        autocomplete: "off",
                        locale: "en-US",
                      },
                      model: {
                        value: _vm.publishDate,
                        callback: function ($$v) {
                          _vm.publishDate = $$v
                        },
                        expression: "publishDate",
                      },
                    }),
                  ],
                  1
                ),
                _c("b-form-input", {
                  staticClass: "form-control kt-input",
                  class: { "is-invalid": _vm.errors.publishDate },
                  attrs: {
                    disabled: _vm.deleted,
                    type: "date",
                    autocomplete: "off",
                  },
                  model: {
                    value: _vm.publishDate,
                    callback: function ($$v) {
                      _vm.publishDate = $$v
                    },
                    expression: "publishDate",
                  },
                }),
                _c(
                  "b-input-group-append",
                  [
                    !_vm.deleted
                      ? _c(
                          "b-dropdown",
                          [
                            _c(
                              "b-dropdown-item-button",
                              {
                                staticClass: "dropdown-item py-2 px-3",
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    $event.preventDefault()
                                    _vm.publishDate = null
                                  },
                                },
                              },
                              [_vm._v(" Clear ")]
                            ),
                            _c(
                              "b-dropdown-item-button",
                              {
                                staticClass: "dropdown-item py-2 px-3",
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    $event.preventDefault()
                                    _vm.publishDate = _vm.today
                                  },
                                },
                              },
                              [_vm._v(" Today ")]
                            ),
                            _c(
                              "b-dropdown-item-button",
                              {
                                staticClass: "dropdown-item py-2 px-3",
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    $event.preventDefault()
                                    _vm.publishDate = _vm.tomorrow
                                  },
                                },
                              },
                              [_vm._v(" Tomorrow ")]
                            ),
                            _c(
                              "b-dropdown-item-button",
                              {
                                staticClass: "dropdown-item py-2 px-3",
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    $event.preventDefault()
                                    _vm.publishDate = _vm.friday
                                  },
                                },
                              },
                              [_vm._v(" Friday ")]
                            ),
                            _c(
                              "b-dropdown-item-button",
                              {
                                staticClass: "dropdown-item py-2 px-3",
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    $event.preventDefault()
                                    _vm.publishDate = _vm.monday
                                  },
                                },
                              },
                              [_vm._v(" Monday ")]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-xl-3 col-lg-4 col-md-6 col-sm-6" },
          [
            _c("label", [_vm._v("Publish Time")]),
            _c(
              "b-input-group",
              [
                _c(
                  "b-input-group-prepend",
                  [
                    _c("b-form-timepicker", {
                      attrs: {
                        disabled: _vm.deleted,
                        "show-seconds": false,
                        "hide-header": "",
                        hour12: "",
                        "no-close-button": "",
                        "button-only": "",
                        locale: "en",
                      },
                      model: {
                        value: _vm.publishTime,
                        callback: function ($$v) {
                          _vm.publishTime = $$v
                        },
                        expression: "publishTime",
                      },
                    }),
                  ],
                  1
                ),
                _c("b-form-input", {
                  class: _vm.errors.publishDate
                    ? "is-invalid form-control kt-input"
                    : "form-control kt-input",
                  attrs: {
                    disabled: _vm.deleted,
                    type: "time",
                    step: "1",
                    autocomplete: "off",
                  },
                  model: {
                    value: _vm.publishTime,
                    callback: function ($$v) {
                      _vm.publishTime = $$v
                    },
                    expression: "publishTime",
                  },
                }),
                _c(
                  "b-input-group-append",
                  [
                    !_vm.deleted
                      ? _c(
                          "b-dropdown",
                          [
                            _c(
                              "b-dropdown-item-button",
                              {
                                staticClass: "dropdown-item py-2 px-3",
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    $event.preventDefault()
                                    _vm.publishTime = null
                                  },
                                },
                              },
                              [_vm._v(" Clear ")]
                            ),
                            _c(
                              "b-dropdown-item-button",
                              {
                                staticClass: "dropdown-item py-2 px-3",
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    $event.preventDefault()
                                    _vm.publishTime = "08:00:00"
                                  },
                                },
                              },
                              [_vm._v(" 8:00 AM ")]
                            ),
                            _c(
                              "b-dropdown-item-button",
                              {
                                staticClass: "dropdown-item py-2 px-3",
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    $event.preventDefault()
                                    _vm.publishTime = "15:00:00"
                                  },
                                },
                              },
                              [_vm._v(" 3:00 PM ")]
                            ),
                            _c(
                              "b-dropdown-item-button",
                              {
                                staticClass: "dropdown-item py-2 px-3",
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    $event.preventDefault()
                                    _vm.publishTime = "00:00:00"
                                  },
                                },
                              },
                              [_vm._v(" Midnight ")]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
        _vm.errors.publishDate
          ? _c("div", { staticClass: "col-12" }, [
              _c("span", { staticClass: "form-text invalid-feedback" }, [
                _vm._v(" " + _vm._s(_vm.errors.publishDate) + " "),
              ]),
            ])
          : _vm._e(),
        _c("div", { staticClass: "col-xl-3 col-lg-4 col-md-6 col-sm-6" }, [
          _c("span", { staticClass: "kt-font-danger" }, [_vm._v("* ")]),
          _c("label", [
            _vm._v(_vm._s(_vm.$t("pages.teacher.assignments.weight"))),
          ]),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.courseWorkWeight,
                expression: "courseWorkWeight",
              },
            ],
            staticClass: "form-control",
            class: { "is-invalid": _vm.errors.weight },
            attrs: { disabled: _vm.deleted, type: "text" },
            domProps: { value: _vm.courseWorkWeight },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.courseWorkWeight = $event.target.value
              },
            },
          }),
          _vm.errors.weight
            ? _c("div", { staticClass: "form-text invalid-feedback" }, [
                _vm._v(" " + _vm._s(_vm.errors.weight) + " "),
              ])
            : _vm._e(),
        ]),
        _c("div", { staticClass: "col-xl-3 col-lg-4 col-md-6 col-sm-6" }, [
          _c("span", { staticClass: "kt-font-danger" }, [_vm._v("* ")]),
          _c("label", [
            _vm._v(_vm._s(_vm.$t("pages.teacher.assignments.maxPoints"))),
          ]),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.maxPoints,
                expression: "maxPoints",
              },
            ],
            staticClass: "form-control",
            class: { "is-invalid": _vm.errors.maxPoints },
            attrs: { disabled: _vm.deleted, type: "text" },
            domProps: { value: _vm.maxPoints },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.maxPoints = $event.target.value
              },
            },
          }),
          _vm.errors.maxPoints
            ? _c("div", { staticClass: "form-text invalid-feedback" }, [
                _vm._v(" " + _vm._s(_vm.errors.maxPoints) + " "),
              ])
            : _vm._e(),
          _c("div", { staticClass: "kt-radio-inline mt-3 ml-2" }, [
            _c("label", { staticClass: "kt-radio kt-radio--brand" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.allowExtraCredit,
                    expression: "allowExtraCredit",
                  },
                ],
                attrs: {
                  type: "checkbox",
                  value: "Points",
                  disabled: _vm.deleted,
                  name: "averagingMethod",
                },
                domProps: {
                  checked: Array.isArray(_vm.allowExtraCredit)
                    ? _vm._i(_vm.allowExtraCredit, "Points") > -1
                    : _vm.allowExtraCredit,
                },
                on: {
                  change: function ($event) {
                    var $$a = _vm.allowExtraCredit,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false
                    if (Array.isArray($$a)) {
                      var $$v = "Points",
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 && (_vm.allowExtraCredit = $$a.concat([$$v]))
                      } else {
                        $$i > -1 &&
                          (_vm.allowExtraCredit = $$a
                            .slice(0, $$i)
                            .concat($$a.slice($$i + 1)))
                      }
                    } else {
                      _vm.allowExtraCredit = $$c
                    }
                  },
                },
              }),
              _c("span"),
              _vm._v(" Allow Extra Credit "),
            ]),
          ]),
        ]),
        _c(
          "div",
          { staticClass: "col-xl-3 col-lg-4 col-md-6 col-sm-6" },
          [
            _c("label", [_vm._v("Due Date")]),
            _c(
              "b-input-group",
              [
                _c(
                  "b-input-group-prepend",
                  [
                    _c("b-form-datepicker", {
                      attrs: {
                        disabled: _vm.deleted,
                        "button-only": "",
                        autocomplete: "off",
                        locale: "en-US",
                      },
                      model: {
                        value: _vm.dueDate,
                        callback: function ($$v) {
                          _vm.dueDate = $$v
                        },
                        expression: "dueDate",
                      },
                    }),
                  ],
                  1
                ),
                _c("b-form-input", {
                  staticClass: "form-control kt-input",
                  class: { "is-invalid": _vm.errors.dueDate },
                  attrs: {
                    type: "date",
                    disabled: _vm.deleted,
                    autocomplete: "off",
                  },
                  model: {
                    value: _vm.dueDate,
                    callback: function ($$v) {
                      _vm.dueDate = $$v
                    },
                    expression: "dueDate",
                  },
                }),
                _c(
                  "b-input-group-append",
                  [
                    !_vm.deleted
                      ? _c(
                          "b-dropdown",
                          [
                            _c(
                              "b-dropdown-item-button",
                              {
                                staticClass: "dropdown-item py-2 px-3",
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    $event.preventDefault()
                                    _vm.dueDate = null
                                  },
                                },
                              },
                              [_vm._v(" Clear ")]
                            ),
                            _c(
                              "b-dropdown-item-button",
                              {
                                staticClass: "dropdown-item py-2 px-3",
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    $event.preventDefault()
                                    _vm.dueDate = _vm.today
                                  },
                                },
                              },
                              [_vm._v(" Today ")]
                            ),
                            _c(
                              "b-dropdown-item-button",
                              {
                                staticClass: "dropdown-item py-2 px-3",
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    $event.preventDefault()
                                    _vm.dueDate = _vm.tomorrow
                                  },
                                },
                              },
                              [_vm._v(" Tomorrow ")]
                            ),
                            _c(
                              "b-dropdown-item-button",
                              {
                                staticClass: "dropdown-item py-2 px-3",
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    $event.preventDefault()
                                    _vm.dueDate = _vm.friday
                                  },
                                },
                              },
                              [_vm._v(" Friday ")]
                            ),
                            _c(
                              "b-dropdown-item-button",
                              {
                                staticClass: "dropdown-item py-2 px-3",
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    $event.preventDefault()
                                    _vm.dueDate = _vm.monday
                                  },
                                },
                              },
                              [_vm._v(" Monday ")]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-xl-3 col-lg-4 col-md-6 col-sm-6" },
          [
            _c("label", [_vm._v("Due Time")]),
            _c(
              "b-input-group",
              [
                _c(
                  "b-input-group-prepend",
                  [
                    _c("b-form-timepicker", {
                      attrs: {
                        "show-seconds": false,
                        disabled: _vm.deleted,
                        hour12: "",
                        "hide-header": "",
                        "no-close-button": "",
                        "button-only": "",
                        locale: "en",
                      },
                      model: {
                        value: _vm.dueTime,
                        callback: function ($$v) {
                          _vm.dueTime = $$v
                        },
                        expression: "dueTime",
                      },
                    }),
                  ],
                  1
                ),
                _c("b-form-input", {
                  class: _vm.errors.dueDate
                    ? "is-invalid form-control kt-input"
                    : "form-control kt-input",
                  attrs: {
                    type: "time",
                    step: "1",
                    disabled: _vm.deleted,
                    autocomplete: "off",
                  },
                  model: {
                    value: _vm.dueTime,
                    callback: function ($$v) {
                      _vm.dueTime = $$v
                    },
                    expression: "dueTime",
                  },
                }),
                _c(
                  "b-input-group-append",
                  [
                    !_vm.deleted
                      ? _c(
                          "b-dropdown",
                          [
                            _c(
                              "b-dropdown-item-button",
                              {
                                staticClass: "dropdown-item py-2 px-3",
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    $event.preventDefault()
                                    _vm.dueTime = null
                                  },
                                },
                              },
                              [_vm._v(" Clear ")]
                            ),
                            _c(
                              "b-dropdown-item-button",
                              {
                                staticClass: "dropdown-item py-2 px-3",
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    $event.preventDefault()
                                    _vm.dueTime = "08:00:00"
                                  },
                                },
                              },
                              [_vm._v(" 8:00 AM ")]
                            ),
                            _c(
                              "b-dropdown-item-button",
                              {
                                staticClass: "dropdown-item py-2 px-3",
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    $event.preventDefault()
                                    _vm.dueTime = "15:00:00"
                                  },
                                },
                              },
                              [_vm._v(" 3:00 PM ")]
                            ),
                            _c(
                              "b-dropdown-item-button",
                              {
                                staticClass: "dropdown-item py-2 px-3",
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    $event.preventDefault()
                                    _vm.dueTime = "00:00:00"
                                  },
                                },
                              },
                              [_vm._v(" Midnight ")]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
        _vm.errors.dueDate
          ? _c("div", { staticClass: "col-12" }, [
              _c("span", { staticClass: "form-text invalid-feedback" }, [
                _vm._v(" " + _vm._s(_vm.errors.dueDate) + " "),
              ]),
            ])
          : _vm._e(),
        _c("div", { staticClass: "col-12" }),
        _c("div", { staticClass: "col-xl-1 col-lg-2 col-sm-4" }, [
          _c("label", [_vm._v(" Id ")]),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.teacherExtCourseWorkId,
                expression: "teacherExtCourseWorkId",
              },
            ],
            staticClass: "form-control",
            attrs: { disabled: _vm.deleted, maxlength: "4", type: "text" },
            domProps: { value: _vm.teacherExtCourseWorkId },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.teacherExtCourseWorkId = $event.target.value
              },
            },
          }),
        ]),
        _c("div", { staticClass: "col-xl-11 col-lg-10 col-sm-8" }, [
          _c("label", [
            _c("span", { staticClass: "kt-font-danger" }, [_vm._v("* ")]),
            _vm._v(
              " " + _vm._s(_vm.$t("pages.teacher.assignments.title")) + " "
            ),
          ]),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.courseWorkTitle,
                expression: "courseWorkTitle",
              },
            ],
            staticClass: "form-control",
            class: { "is-invalid": _vm.errors.courseWorkTitle },
            attrs: { disabled: _vm.deleted, type: "text" },
            domProps: { value: _vm.courseWorkTitle },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.courseWorkTitle = $event.target.value
              },
            },
          }),
          _vm.errors.courseWorkTitle
            ? _c("div", { staticClass: "form-text invalid-feedback" }, [
                _vm._v(" " + _vm._s(_vm.errors.courseWorkTitle) + " "),
              ])
            : _vm._e(),
        ]),
        _vm.isNew
          ? _c("CourseWorkLinker", {
              scopedSlots: _vm._u(
                [
                  {
                    key: "header",
                    fn: function () {
                      return [
                        _c("div", { staticClass: "row" }, [
                          _c("div", { staticClass: "col-12" }, [
                            _c("div", { staticClass: "form-group mb-3" }, [
                              _c("label", { staticClass: "kt-font-md w-100" }, [
                                _vm._v(" Assigned Courses "),
                              ]),
                            ]),
                          ]),
                        ]),
                      ]
                    },
                    proxy: true,
                  },
                  {
                    key: "error",
                    fn: function () {
                      return [
                        _vm.errors.assignedCourses
                          ? _c("span", { staticClass: "kt-font-danger" }, [
                              _vm._v(
                                " " + _vm._s(_vm.errors.assignedCourses) + " "
                              ),
                            ])
                          : _vm._e(),
                      ]
                    },
                    proxy: true,
                  },
                ],
                null,
                false,
                2772706458
              ),
            })
          : _vm._e(),
        _c(
          "div",
          { staticClass: "col-12" },
          [
            _c("label", [_vm._v("Assignment Description")]),
            !_vm.deleted
              ? _c("v-md-editor", {
                  staticClass: "v-md-editor",
                  attrs: {
                    height: "400px",
                    "right-toolbar": _vm.rightToolbar,
                    "left-toolbar": _vm.leftToolbar,
                    mode: "edit",
                  },
                  model: {
                    value: _vm.courseWorkDescription,
                    callback: function ($$v) {
                      _vm.courseWorkDescription = $$v
                    },
                    expression: "courseWorkDescription",
                  },
                })
              : _c("textarea", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.courseWorkDescription,
                      expression: "courseWorkDescription",
                    },
                  ],
                  staticClass: "form-control w-100",
                  attrs: { rows: "10", disabled: "disabled" },
                  domProps: { value: _vm.courseWorkDescription },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.courseWorkDescription = $event.target.value
                    },
                  },
                }),
            _vm._v(" "),
            _vm._m(1),
          ],
          1
        ),
        _vm.errors.publishDate
          ? _c("div", { staticClass: "col-12" }, [
              _c("span", { staticClass: "form-text invalid-feedback" }, [
                _vm._v(" " + _vm._s(_vm.errors.publishDate) + " "),
              ]),
            ])
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("label", { staticClass: "w-100" }, [
      _c("span", { staticClass: "kt-font-danger" }, [_vm._v("* ")]),
      _vm._v(" Grading Category "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c(
      "span",
      {
        staticClass: "form-text text-muted",
        staticStyle: { "font-size": "0.8rem" },
      },
      [
        _c("i", { staticClass: "fab fa-markdown" }),
        _vm._v(" You can use "),
        _c(
          "a",
          { attrs: { target: "_blank", href: "https://commonmark.org/help/" } },
          [_vm._v(" Markdown ")]
        ),
        _vm._v(" to format your description. "),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }